<template>
  <div>
    <vx-page-title-actions>
      <vx-button
        :can="canCreate"
        variant="primary"
        class="mr-1"
        :to="createCurrency"
      >
        Add
      </vx-button>
      <vx-button
        variant="secondary"
        @click="updateCurrencyRates"
      >
        Update Currency Rates
      </vx-button>
    </vx-page-title-actions>
    <vx-table
      ref="tableRef"
      :resource="resource"
      :columns="columns"
      hide-per-page
    />
  </div>
</template>

<script>
import { VxCellBadges, VxCellButtons, VxCellDate, VxCellPercentage, VxTable } from '@/components/table'
import VxPageTitleActions from '@/components/layout/VxPageTitleActions'
import { VxButton } from '@/components/buttons'
import { permissions, useCan } from '@/plugins/acl'
import { boolStatuses, currencies, passDataToResource, useResource } from '@/services/resources'
import { boolStatusFormat } from '@/services/utils'
import { buildRoute, path } from '@/router'
import { buttons } from '@/components/table/vx-table-cells/vx-cell-button'

export default {
  name: 'Currencies',
  components: {
    VxTable,
    VxPageTitleActions,
    VxButton,
    VxCellBadges,
    VxCellButtons,
    VxCellDate,
    VxCellPercentage
  },
  setup () {
    const canCreate = useCan(permissions.currencyCreate)
    const canEdit = useCan(permissions.currencyUpdate)

    const createCurrency = path.createCurrency
    const resource = useResource(currencies.getAll)

    const updateCurrencyRates = async () => {
      const { can, callRequest } = useResource(currencies.updateExchangeRates)

      if (can) await callRequest()
    }

    const actionButtons = ({ id }) => {
      const editButton = {
        ...buttons.edit,
        can: canEdit,
        to: buildRoute(path.updateCurrency, { params: { id } })
      }

      const deleteButton = {
        ...buttons.delete,
        resource: passDataToResource(currencies.delete, {
          requestParams: { urlParams: { id } }
        })
      }

      return [editButton, deleteButton]
    }

    const columns = [
      { key: 'id', sortable: true },
      { key: 'name', sortable: true },
      {
        key: 'code',
        sortable: true,
        component: VxCellBadges
      },
      { key: 'exchange_rate' },
      { key: 'official_exchange_rate' },
      {
        key: 'is_fake',
        label: 'Is Virtual',
        component: VxCellBadges,
        tdAttr: {
          colors: {
            [boolStatuses['1']]: 'secondary',
            [boolStatuses['0']]: 'dark'
          }
        },
        formatter: (isFake) => boolStatusFormat(isFake)
      },
      {
        key: 'is_active',
        label: 'Status',
        component: VxCellBadges,
        tdAttr: {
          colors: {
            Active: 'success',
            Inactive: 'dark'
          }
        },
        formatter: (value) => {
          return value ? 'Active' : 'Inactive'
        }
      },
      {
        key: 'deviation_limit',
        component: VxCellPercentage,
        tdAttr: {
          alreadyInteger: true
        }
      },
      {
        key: 'updated_at',
        sortable: true,
        component: VxCellDate
      },
      {
        key: 'actions',
        component: VxCellButtons,
        tdAttr: (value, key, item) => ({
          buttons: actionButtons(item)
        })
      }
    ]

    return {
      canCreate,
      createCurrency,
      updateCurrencyRates,
      resource,
      columns
    }
  }
}
</script>
